.Loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loading > div {
    background-color: var(--background-color-dark-opacity);
    color: var(--general-text);
    border-radius: 0.5em;
    font-size: 1.7rem;
    line-height: 2rem;
    margin: 1em 2em;
    padding: 0.5em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Loading > div > span {
    color: var(--highlighted-color);
    width: 2rem;
    height: 2rem;
    margin-left: 1em;
}