#Sidebar {
    overflow: hidden;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: -1;
}

#Sidebar .content {
    padding: 0 1em;
    z-index: 1;
}

#Sidebar .content .menu {
    background-color: rgba(228, 219, 233, 6%);
    margin: 1em 0;
    border-radius: 0.4em;
    border-style: solid;
    border-color: white;
    border-width: 1px;
    padding: 1.5em 1em 3em 1em;
}

#Sidebar .title {
    font-size: 1.2rem;
    margin-top: 0.8em;
}

#Sidebar .menu-text {
    margin-left: 0.2em;
    padding: 0.3em 0;
}

#Sidebar .child {
    position: relative;
    left: 0.1em;
}

#Sidebar a {
    color: white;
}

#Sidebar ul {
    list-style: none;
}

#Sidebar ul li {
    margin-bottom: 0.2em;
}
