#OAuth2 {
    width: fit-content;
}

#OAuth2 h4 {
    font-size: 1rem;
}

#OAuth2 .username, #OAuth2 .logout {
    text-transform: uppercase;
}

#OAuth2 .logout {
    font-size: 1.1rem;
}