:root {
    --border-color: white;
    --background-color: #021c29;
    --background-white-opacity: rgba(0, 0, 0, 20%);
    --background-color-light: #143347;
    --background-color-dark-opacity: rgba(0, 0, 0, 50%);
    --general-text: #ebf3ff;
    --primary-text: #37b8e3;
    --secondary-text: #3091b3;
    --tertiary-text: #226c85;
    --link-color: wheat;
    --link-secondary-color: #2c7cd6;
    --highlighted-color: wheat;
    --button-text-color: #fff;
    --button-color: #05453d;
    --button-color-hover: #032e28;
    --box-shadow: #031823;
    --box-shadow-hover: #02111a;
}

@keyframes zoomIn {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

/* For Webkit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar for horizontal scroll */
}

::-webkit-scrollbar-thumb {
    background-color: #010d14; /* Scrollbar color */
    border-radius: 10px; /* Roundness */
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--background-color-light); /* Darker color on hover */
}

::-webkit-scrollbar-track {
    background: var(--background-color); /* Background of the scrollbar track */
}

html {
    color: var(--general-text);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
}

body {
    background: linear-gradient(0deg, var(--background-color) 50%, var(--background-color-light) 100%) no-repeat fixed;
    background-size: cover;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, p, h1, h2, h3, h4, h5, h6 {
    color: white;
    font-family: sans-serif;
    margin: 0;
    font-weight: normal;
    background: transparent;
    text-decoration: none;
}

a, p {
    font-family: 'Roboto', sans-serif;
}

/* Override autofilling styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent !important;
    color: var(--background-color) !important;
    box-shadow: var(--box-shadow);
    -webkit-text-fill-color: var(--background-color) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--background-color) inset !important;
    border-width: 0 !important;
}

h1.page {
    background-color: rgba(0, 0, 0, 50%);
    padding: 0.5em 0;
    color: wheat;
    width: 100%;
    text-align: center;
    font-size: 3rem;
    margin-top: 20px;
    transition: font-size 0.2s ease-in-out;
}

h1.page:hover {
    font-size: 3.2rem;
}

ul.help-text {
    list-style-type: none;
    padding: 0;
    margin: 0;
}