#Header {
    border-bottom: 1px solid white;
    height: 5rem;
}

#Header #arrow {
    height: 3.5em;
}

#Header #site-name {
    color: wheat;
    font-size: 1.5rem;
    margin-left: 0.2em;
}