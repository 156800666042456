:root {
    --margin-top: 5rem;
}

#App {

}

#App #Left {
    transition: linear 200ms;
}

#App #Main {
    height: calc(100vh - var(--margin-top));
    min-height: calc(100vh - var(--margin-top));
    padding: 0 1em 1em 1em;
    margin-top: var(--margin-top);
    transition: linear 200ms;
    overflow: auto;
}

@media (max-width: 768px) {
    #App {
        font-size: 0.5rem;
    }
}